
    import { defineComponent } from 'vue';
    import { IonIcon }         from '@ionic/vue';
    import { useI18n }         from 'vue-i18n';
    import { scanOutline }     from 'ionicons/icons';
    import { decode }          from 'querystring';
    import FlussonicMsePlayer  from '@flussonic/flussonic-mse-player'
    export default defineComponent({
        name: 'CameraWebRTCPlayer',
        props: [
            'url',
            'className',
            'cameraId',
            'controls',
            'muted',
            'autoplay',
            'playbackEnabled',
            'microphoneEnabled',
            'iOSCompatFullScreen',
            'pip',
            'onPlaying',
            'onError'
        ],
        components: { IonIcon },
        setup()
        {
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            return {
                scanOutline, t
            }
        },
        data() {
            return {
                player: null,
                dragged:          false,
                iOSCompatControls: false,
                retryPause:       2000,
                pc:               null,
                ws:               null,
                bufferTimeout:    null,
                isVideoPlaying:   false
            }
        },
        async mounted()
        {
            //@ts-ignore
            // this.initializeFlussonicPlayer();
            // // this.initializeWebRTC();
        },
        beforeUnmount()
        {
            // console.log('BEFORE UNMOUNT');
            // this.stop();
        },
        // updated()
        // {
        //     setTimeout(() => this.initializeWebRTC(), 300);
        // },
        watch: {
            // url: {
            //     immediate: true,
            //     handler (val, oldVal) {
            //         //@ts-ignore
            //         var self = this;
            //         //@ts-ignore
            //         var video = self.$refs["videortc"] as HTMLMediaElement;
            //         var fnc = async function() {
            //             //@ts-ignore
            //             if (typeof video != 'undefined') {
            //                 //@ts-ignore
            //                 video.pause();
            //                 //@ts-ignore
            //                 video.currentTime = 0;
            //                 //@ts-ignore
            //                 video.src = undefined;
            //                 //@ts-ignore
            //                 if (self.pc !== null) {
            //                     //@ts-ignore
            //                     self.pc.close();
            //                     //@ts-ignore
            //                     self.pc = null;
            //                 }
            //                 //@ts-ignore
            //                 if (self.ws !== null) {
            //                     //@ts-ignore
            //                     self.ws.close();
            //                     //@ts-ignore
            //                     self.ws = null;
            //                 }
            //                 //@ts-ignore
            //                 await self.initializeWebRTC();
            //                 //@ts-ignore
            //                 video.play();
            //             }
            //         };
            //         fnc();
            //     }
            // }
        },
        methods: {
            visibilityChanged (isVisible, entry) {
                // if (isVisible) {
                //     //@ts-ignore
                //     this.initializeWebRTC();
                // } else {
                //     stop();
                // }
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            async waitForUserGesture() {
                return new Promise((resolve) => {
                    const handler = () => {
                        //@ts-ignore
                        resolve();
                        document.removeEventListener('click', handler);
                    };
                    document.addEventListener('click', handler, { once: true });
                });
            },
            stop()
            {
                //@ts-ignore
                if (this.pc !== null) {
                    //@ts-ignore
                    this.pc.close();
                    //@ts-ignore
                    this.pc = null;
                }
                //@ts-ignore
                if (this.ws !== null) {
                    //@ts-ignore
                    this.ws.close();
                    //@ts-ignore
                    this.ws = null;
                }
            },
            async initializeWebRTC() {
                //@ts-ignore
                if (this.url == '') return;
                //@ts-ignore
                var video            = this.$refs["videortc"] as HTMLMediaElement;
                //@ts-ignore
                video.controls       = this.controls;
                //@ts-ignore
                video.muted          = this.muted || false;
                //@ts-ignore
                video.autoplay       = this.autoplay || true;
                //@ts-ignore
                video.playsInline    = this.playsInline || true;
                video.addEventListener("progress", this.handleProgress);
                //@ts-ignore
                const aPc            = this.PeerConnection(this.microphoneEnabled ? 'video+audio+microphone' : 'video+audio');
                await this.connect(aPc);
            },
            async PeerConnection(media) {
                const pc = new RTCPeerConnection({
                    bundlePolicy: 'max-bundle',
                    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
                });
                const localTracks = [];
                try {
                    if (/camera|microphone/.test(media)) {
                        const tracks = await this.getMediaTracks('user', {
                            video: media.includes('camera'),
                            audio: media.includes('microphone'),
                        });
                        tracks.forEach((track) => {
                            //@ts-ignore
                            this.pc.addTransceiver(track, { direction: 'sendonly' });
                            //@ts-ignore
                            if (track.kind === 'video') localTracks.push(track);
                        });
                    }
                    if (media.includes('display')) {
                        const tracks = await this.getMediaTracks('display', {
                            video: true,
                            audio: media.includes('speaker'),
                        });
                        tracks.forEach((track) => {
                            //@ts-ignore
                            this.pc.addTransceiver(track, { direction: 'sendonly' });
                            //@ts-ignore
                            if (track.kind === 'video') localTracks.push(track);
                        });
                    }
                    if (/video|audio/.test(media)) {
                        const tracks = ['video', 'audio']
                            .filter((kind) => media.includes(kind))
                            .map((kind) => pc.addTransceiver(kind, { direction: 'recvonly' }).receiver.track);
                        //@ts-ignore
                        localTracks.push(...tracks);
                    }
                    //@ts-ignore
                    var video = this.$refs["videortc"] as HTMLMediaElement;
                    video.srcObject = new MediaStream(localTracks);
                    video.srcObject.addEventListener("progress", this.handleProgress);
                    return pc;
                } catch (error) {
                    console.error('Error initializing PeerConnection:', error);
                    return null;
                }
            },
            async connect(aPcPromise) {
                try {
                    const aPc = await aPcPromise;
                    if (!aPc) return;
                    //@ts-ignore
                    this.pc = aPc;
                    //@ts-ignore
                    const ws = new WebSocket(this.url);
                    //@ts-ignore
                    this.ws = ws;
                    ws.binaryType = 'arraybuffer',
                    ws.addEventListener('open', () => {
                        //@ts-ignore
                        this.pc.addEventListener('icecandidate', (ev) => {
                            if (!ev.candidate) return;
                            const msg = {
                                type: 'webrtc/candidate',
                                value: ev.candidate.candidate,
                            };
                            ws.send(JSON.stringify(msg));
                        });
                        //@ts-ignore
                        this.pc.createOffer()
                        //@ts-ignore
                        .then((offer) => this.pc.setLocalDescription(offer))
                        .then(() => {
                            const msg = {
                                type: 'webrtc/offer',
                                //@ts-ignore
                                value: this.pc.localDescription?.sdp,
                            };
                            ws.send(JSON.stringify(msg));
                        });
                    });
                    ws.addEventListener('message', (ev) => {
                        const msg = JSON.parse(ev.data);
                        if (msg.type === 'webrtc/candidate') {
                            if (msg.value != null) {
                                let ipRegex = /\b(?:[0-9]{1,3}\.){3}[0-9]{1,3}\b/;
                                let replacedString = msg.value.replace(ipRegex, '209.172.45.130');
                                //@ts-ignore
                                this.pc?.addIceCandidate({ candidate: replacedString, sdpMid: '0' });
                            }
                        } else if (msg.type === 'webrtc/answer') {
                            if (msg.value != null) {
                                //@ts-ignore
                                this.pc?.setRemoteDescription({
                                    type: 'answer',
                                    sdp: msg.value,
                                });
                            }
                        }
                    });
                } catch (error) {
                    console.error('Error connecting to WebSocket:', error);
                }
            },
            handleLoadedData(event) {
                console.log('loaded data');
            },
            handleProgress(event) {
                console.log('Handling progress...');
                //@ts-ignore
                var video = this.$refs["videortc"] as HTMLMediaElement;
                if (video && video.paused) return;
                //@ts-ignore
                if (this.bufferTimeout) {
                    //@ts-ignore
                    clearTimeout(this.bufferTimeout);
                    //@ts-ignore
                    this.bufferTimeout = null;
                }
                //@ts-ignore
                this.bufferTimeout = setTimeout(() => {
                    console.log('stalled');
                    //@ts-ignore
                    if (document.visibiliytState === 'visible' && this.pc) {
                        //@ts-ignore
                        this.onError?.('stalled');
                    }
                }, 3000);
            },
            handlePlaying(event) {
                console.log('video is playing');
                this.isVideoPlaying = true;
                //@ts-ignore
                this.onPlaying?.();
            },
            handleError(e) {
                console.log('Handling player error: ', e.target.error);
                if (e.target.error.code == MediaError.MEDIA_ERR_NETWORK) {
                    console.log('Media ERR NETWORK');
                    this.onError?.("startup");
                }
            },
            handleClick(event) {
                if (this.iOSCompatFullScreen) {
                    this.iOSCompatControls = !this.iOSCompatControls
                }
            },
            async getMediaTracks(media, constraints) {
                try {
                    const stream = media === "user"
                        ? await navigator.mediaDevices.getUserMedia(constraints)
                        : await navigator.mediaDevices.getDisplayMedia(constraints);
                    return stream.getTracks();
                } catch (e) {
                    return [];
                }
            },
            handleDragStart(e) {
                console.log('dragging RTC video', e)
                //@ts-ignore
                this.dragged = true;
                //@ts-ignore
                this.$root.$draggedCameraComponent = this;
            },
            handleDragEnd(e) {
                console.log('dragged RTC video', e)
                //@ts-ignore
                this.dragged = false;
            },
            fullscreen() {
                //@ts-ignore
                var video  = this.$refs["video"] as HTMLDivElement;
                if (!document.fullscreenElement) {
                    video.requestFullscreen();
                } else {
                    //@ts-ignore
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    //@ts-ignore
                    } else if (document.msExitFullscreen) {
                        //@ts-ignore
                        document.msExitFullscreen();
                    //@ts-ignore
                    } else if (document.mozCancelFullScreen) {
                        //@ts-ignore
                        document.mozCancelFullScreen();
                    //@ts-ignore
                    } else if (document.webkitExitFullscreen) {
                        //@ts-ignore
                        document.webkitExitFullscreen();
                    }
                }
            },
            pad2(n) {
                return n <= 9 ? '0' + n : '' + n;
            },
            humanTime(utcOrLive, lt = true) {
                // $FlowFixMe: string > 0 is always false
                if (!(utcOrLive > 0)) {
                    return '';
                }
                // $FlowFixMe: just for flow
                const utc = utcOrLive;
                var d = new Date();
                d.setTime(utc * 1000);
                var localTime = !(lt === false);
                var h = localTime ? d.getHours() : d.getUTCHours();
                var m = localTime ? d.getMinutes() : d.getUTCMinutes();
                var s = localTime ? d.getSeconds() : d.getUTCSeconds();
                return this.pad2(h) + ':' + this.pad2(m) + ':' + this.pad2(s);
            },
            play(videotrack = undefined, audiotrack) {
                if (!videotrack) {
                    audiotrack = undefined;
                }
                //@ts-ignore
                this.player.play(videotrack, audiotrack).then((success) => {
                    console.log('resolve', success);
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            hidePlayButton() {
                const playButton = this.$refs["playButton"];
                //@ts-ignore
                playButton.style.display = 'none';
                // window.removeEventListener('click', window.hidePlayButton);
                // window.removeEventListener('touchstart', window.hidePlayButton);
                // window.autoplayFunc();
            },
            setTracks() {
                const videoTracksSelect = this.$refs["videoTracks"];
                const audioTracksSelect = this.$refs["audioTracks"];
                //@ts-ignore
                const videoTrackId = videoTracksSelect.options[videoTracksSelect.selectedIndex].value;
                //@ts-ignore
                const audioTrackId = audioTracksSelect.options[audioTracksSelect.selectedIndex].value;
                console.log({ videoTracksSelect }, videoTrackId, audioTrackId);
                //@ts-ignore
                this.player.setTracks([videoTrackId, audioTrackId]);
            },
            seek() {
                const seek = this.$refs["seek"];
                let value;
                if (!seek || seek instanceof HTMLInputElement) {
                    //@ts-ignore
                    if (seek.value === 'live') {
                        value = 'live';
                    } else {
                        //@ts-ignore
                        value = parseInt(seek.value, 10);
                        value = value > 0 ? value : void 0;
                    }
                }
                if (value) {
                    //@ts-ignore
                    this.player.seek(value);
                } else throw new Error('incorrect input!');
            },
            unmute() {
                const muted = this.$refs["muted"];
                //@ts-ignore
                if (this.player && this.player.media) {
                    //@ts-ignore
                    if (this.player.media.muted) {
                        //@ts-ignore
                        this.player.media.muted = false;
                        //@ts-ignore
                        muted.innerText = 'Mute';
                    } else {
                        //@ts-ignore
                        this.player.media.muted = true;
                        //@ts-ignore
                        muted.innerText = 'Unmute';
                    }
                }
            },
            initializeFlussonicPlayer() {
                var self = this;
                let streamer_ws = 'wss://media.lexoh.com:52445';
                let stream_name = self.url.replace('https://media.lexoh.com:52445/', '').split('/')[0];
                let videotrack, audiotrack, from, token, videoQuality, statsSendEnable = false, statsSendTime = 60;
                // parse query string
                let query = window.location.search;
                if (query) {
                    let qs = decode(query.replace(/^\?/, ''));
                    if (qs.host) {
                        //@ts-ignore
                        streamer_ws = qs.host;
                    }
                    if (qs.name) {
                        //@ts-ignore
                        stream_name = qs.name;
                    }
                    if (qs.videotrack) {
                        videotrack = qs.videotrack;
                    }
                    if (qs.audiotrack) {
                        audiotrack = qs.audiotrack;
                    }
                    if (qs.from) {
                        //@ts-ignore
                        from = qs.from;
                    }
                    if (qs.token) {
                        token = qs.token;
                    }
                    if (qs.videoQuality) {
                        videoQuality = qs.videoQuality;
                    }
                    if (qs.statsSendEnable) {
                        statsSendEnable = qs.statsSendEnable === 'true' ? true : false;
                    }
                    if (qs.statsSendTime) {
                        statsSendTime = Number(qs.statsSendTime);
                    }
                }
                let url = `${streamer_ws}/${stream_name}/mse_ld${token ? `?token=${token}` : ''}`;
                const element               = this.$refs["player"];
                const videoTracksSelect     = this.$refs["videoTracks"];
                const audioTracksSelect     = this.$refs["audioTracks"];
                const mbrControls           = this.$refs["mbr-controls"];
                const loading               = this.$refs["loading"];
                const stallingLabel         = this.$refs["stallingLabel"];
                const showStallingIndicator = (value) => {
                    //@ts-ignore
                    stallingLabel.innerText = '' + value;
                };
                let showFirstFrameUTC = false;
                // let timeLineChart = []
                // let graphUTCLabels = []
                // let graphCurrentTime = []
                // let graphBufferedTime = []
                let graphBufferedLength = [];
                let readySt = [];
                let mseVideoBufferSize = [];
                let mseAudioBufferSize = [];
                let messagesUTC = [];
                // const messagesTimelag = []
                const opts = {
                    debug: false,
                    connectionRetries: 3,
                    errorsBeforeStop: 1,
                    retryMuted: true,
                    onCrashTryVideoOnly: true,
                    maxBufferDelay: 2,
                    videotrack,
                    audiotrack,
                    // preferHQ: true,
                    videoQuality,
                    progressUpdateTime: 100,
                    wsReconnect: true,
                    statsSendEnable,
                    statsSendTime, // in seconds
                    // sentryConfig: 'https://*some sentry instance*',
                    onStartStalling: () => {
                        showStallingIndicator('start stalling');
                        //@ts-ignore
                        loading.classList.add('visible');
                    },
                    onEndStalling: () => {
                        showStallingIndicator('end stalling');
                        //@ts-ignore
                        loading.classList.remove('visible');
                    },
                    onSeeked: () => {
                        showFirstFrameUTC = true;
                    },
                    onProgress: (utc) => {
                        if (showFirstFrameUTC) {
                            console.log('%c first frame after action: ' + self.humanTime(utc) + ' ' + utc, 'background: red');
                            showFirstFrameUTC = false;
                        }
                        // timeLineChart.push({
                        //   x: new Date(),
                        //   // y: 0,
                        // })
                        // if (timeLineChart.length === 100) {
                        //   timeLineChart.shift()
                        // }
                        // eventsChart.update()
                    },
                    onDisconnect: (status) => {
                        console.log('Websocket status:', status);
                        const restart = () => {
                            //@ts-ignore
                            self.player.restart();
                            clearInterval(restartTimer);
                        };
                        let restartTimer = setInterval(restart, 1000);
                    },
                    onMediaInfo: (rawMetaData) => {
                        console.log('rawMetaData:', rawMetaData);
                        let videoOptions, audioOptions;
                        //@ts-ignore
                        const videoTracks = self.player.getVideoTracks();
                        //@ts-ignore
                        const audioTracks = self.player.getAudioTracks();
                        if (videoTracks) {
                            videoOptions = videoTracks.map((v, i) =>`<option value="${v['track_id']}">${v['bitrate']} ${v['codec']} ${v['fps']} ${v['width']}x${v['height']}</option>`);
                            //@ts-ignore
                            videoTracksSelect.innerHTML = videoOptions.join('');
                        }
                        if (audioTracks) {
                            audioOptions = audioTracks.map((v) => `<option value="${v['track_id']}">${v['bitrate']} ${v['codec']} ${v['lang']}</option>`);
                            audioOptions.push('<option value="">None</option>');
                            //@ts-ignore
                            audioTracksSelect.innerHTML = audioOptions.join('');
                        }
                        //@ts-ignore
                        mbrControls.style.display = 'block';
                    },
                    onError: (err) => {
                        console.log('***** ERRROR', err);
                        if (typeof err != 'undefined') {
                            //@ts-ignore
                            // self.player.restart();
                        }
                    },
                    // onEvent: (event) => {
                    //   console.log('EVENT', event);
                    //   if (typeof event === 'object' && event.type) {
                    //     let color = 'gray';
                    //     switch (event.type) {
                    //       case 'waiting':
                    //         color = 'red';
                    //         break;
                    //       case 'playing':
                    //         color = 'green';
                    //         break;
                    //       default:
                    //         color = 'gray';
                    //         break;
                    //     }
                    //     const time = new Date();
                    //     myChart.xAxis[0].addPlotBand({
                    //       label: { text: event.type },
                    //       color,
                    //       width: 2,
                    //       value: Date.now(time),
                    //       zIndex: 3,
                    //     });
                    //   }
                    // },
                    onAutoplay: (func) => {
                        // console.log('onAutoplay', func)
                        const playButton = this.$refs["playButton"];
                        //@ts-ignore
                        playButton.style.display = 'flex';
                        // window.autoplayFunc = func.bind(this);
                        // window.addEventListener('click', window.hidePlayButton);
                        // window.addEventListener('touchstart', window.hidePlayButton);
                    },
                    onMuted: () => {
                        console.log('[onMuted]');
                    },
                    // onStats: (stats) => {
                    //   const {endTime, currentTime, videoBuffer, audioBuffer, timestamp, readyState, networkState} = stats
                    //   mseVideoBufferSize.push([timestamp, videoBuffer])
                    //   mseAudioBufferSize.push([timestamp, audioBuffer])
                    //   const readyIndicator = document.getElementById('indicator')
                    //   readyIndicator.className = ''
                    //   switch (readyState) {
                    //     case 0:
                    //       readyIndicator.classList.add('black')
                    //       break
                    //     case 1:
                    //       readyIndicator.classList.add('gray')
                    //       break
                    //     case 2:
                    //       readyIndicator.classList.add('red')
                    //       break
                    //     case 3:
                    //       readyIndicator.classList.add('yellow')
                    //       break
                    //     case 4:
                    //       readyIndicator.classList.add('green')
                    //       break
                    //     default:
                    //       readyIndicator.classList.add('gray')
                    //       break
                    //   }
                    //   const networkIndicator = document.getElementById('nIndicator')
                    //   networkIndicator.className = ''
                    //   switch (networkState) {
                    //     case 0:
                    //       networkIndicator.classList.add('black')
                    //       break
                    //     case 1:
                    //       networkIndicator.classList.add('yellow')
                    //       break
                    //     case 2:
                    //       networkIndicator.classList.add('green')
                    //       break
                    //     case 3:
                    //       networkIndicator.classList.add('red')
                    //       break
                    //     default:
                    //       networkIndicator.classList.add('gray')
                    //       break
                    //   }
                    //   graphBufferedLength.push([timestamp, (endTime - currentTime) * 1000])
                    //   readySt.push([timestamp, readyState])
                    // },
                    onMessage: (messageStats) => {
                        const { utc, messageTimeDiff } = messageStats;
                        //@ts-ignore
                        messagesUTC.push([utc, messageTimeDiff]);
                    },
                    onPause: () => {
                        const pauseButton = this.$refs["pauseButton"];
                        //@ts-ignore
                        pauseButton.style.display = 'flex';
                    },
                    onResume: () => {
                        const pauseButton = this.$refs["pauseButton"];
                        //@ts-ignore
                        pauseButton.style.display = 'none';
                    },
                };
                self.player = new FlussonicMsePlayer(element, url, opts);
                self.play(videotrack, audiotrack);
                //   let myChart = Highcharts.stockChart('container', {
                //     // Create the chart
                //     chart: {},
                //     time: {
                //       useUTC: true,
                //     },
                //     rangeSelector: {
                //       buttons: [
                //         {
                //           count: 1,
                //           type: 'minute',
                //           text: '1M',
                //         },
                //         {
                //           count: 5,
                //           type: 'minute',
                //           text: '5M',
                //         },
                //         {
                //           type: 'all',
                //           text: 'All',
                //         },
                //       ],
                //       inputEnabled: false,
                //       selected: 0,
                //     },
                //     title: {
                //       text: 'MSELD Statistics',
                //     },
                //     // exporting: {
                //     //   enabled: false,
                //     // },
                //     xAxis: {
                //       plotBands: [],
                //       plotLines: [],
                //     },
                //     yAxis: [
                //       {
                //         title: {
                //           text: 'Milliseconds',
                //         },
                //         align: 'left',
                //       },
                //       {
                //         title: {
                //           text: 'State',
                //         },
                //         opposite: true,
                //         align: 'right',
                //         // floor: 0,
                //         // ceiling: 4,
                //         max: 4,
                //       },
                //     ],
                //     series: [
                //       {
                //         name: 'WS message lag',
                //         data: [],
                //       },
                //       {
                //         name: 'Media Element have seconds in buffer',
                //         data: [],
                //       },
                //       {
                //         name: 'Ready State',
                //         type: 'line',
                //         yAxis: 1,
                //         data: [],
                //         max: 4,
                //         zones: [
                //           {
                //             value: 0,
                //             color: 'black',
                //           },
                //           {
                //             value: 1,
                //             color: 'gray',
                //           },
                //           {
                //             value: 2,
                //             color: 'red',
                //           },
                //           {
                //             value: 3,
                //             color: 'yellow',
                //           },
                //           {
                //             value: 4,
                //             color: 'green',
                //           },
                //           {
                //             color: 'gray',
                //           },
                //         ],
                //       },
                //     ],
                //   });
                //   let myMseChart = Highcharts.stockChart('container2', {
                //     // Create the chart
                //     chart: {},
                //     time: {
                //       useUTC: true,
                //     },
                //     rangeSelector: {
                //       buttons: [
                //         {
                //           count: 1,
                //           type: 'minute',
                //           text: '1M',
                //         },
                //         {
                //           count: 5,
                //           type: 'minute',
                //           text: '5M',
                //         },
                //         {
                //           type: 'all',
                //           text: 'All',
                //         },
                //       ],
                //       inputEnabled: false,
                //       selected: 0,
                //     },
                //     title: {
                //       text: 'MSELD Buffer Statistics',
                //     },
                //     // xAxis: {
                //     //   plotBands: [],
                //     //   plotLines: [],
                //     // },
                //     yAxis: [
                //       {
                //         title: {
                //           text: 'Bytes',
                //         },
                //         align: 'left',
                //       },
                //     ],
                //     series: [
                //       {
                //         name: 'WS audio buffer',
                //         data: [],
                //       },
                //       {
                //         name: 'WS video buffer',
                //         data: [],
                //       },
                //     ],
                //   });
                //   setInterval(() => {
                //     const prepare = function (data) {
                //       // const maxElements = 5000
                //       data.sort((a, b) => {
                //         return a[0] - b[0];
                //       });
                //       // if (data.length >= maxElements) {
                //       //   data = data.splice(data.length - maxElements, data.length)
                //       // }
                //       return data;
                //     };
                //     messagesUTC = prepare(messagesUTC);
                //     graphBufferedLength = prepare(graphBufferedLength);
                //     readySt = prepare(readySt);
                //     mseAudioBufferSize = prepare(mseAudioBufferSize);
                //     mseVideoBufferSize = prepare(mseVideoBufferSize);
                //     myChart.series[0].setData(messagesUTC);
                //     myChart.series[1].setData(graphBufferedLength);
                //     myChart.series[2].setData(readySt);
                //     myMseChart.series[0].setData(mseAudioBufferSize);
                //     myMseChart.series[1].setData(mseVideoBufferSize);
                //   }, 5000);
            }
        }
    });
